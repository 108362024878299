import { useState, useEffect } from 'react';

import { GiHamburgerMenu } from 'react-icons/gi'
import { IoSchoolOutline } from 'react-icons/io5';
import { AiFillHtml5, AiOutlineMail, AiFillLinkedin, AiFillGithub } from 'react-icons/ai';
import { DiCss3, DiJavascript, DiPython } from 'react-icons/di'
import { FaDocker, FaGitAlt } from 'react-icons/fa';

import MenuModal from './components/MenuModal';

import api from './services/api';
import styles from './styles/Home.module.scss';

function App() {

  useEffect(() => {
    async function requestApi() {
      const { data } = await api.get('repos', {
        params: {
          per_page: 6,
          sort: "created",
        }
      });
      setRepos(data);
    }

    requestApi();
  }, [])

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [repos, setRepos] = useState([]);


  function handleMenuMobile() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  return (
    <div className={styles.App}>

      <header>

        <div className={styles.logo}>
          <a href="/">Caio Gabriel</a>
          {isMobileMenuOpen ? <></> : <GiHamburgerMenu onClick={handleMenuMobile} />}
        </div>

        <nav className={styles.menuNav}>
          <ul>
            <li><a href="#about">Sobre</a></li>
            <li><a href="#skills">Habilidades</a></li>
            <li><a href="#projects">Projetos</a></li>
            <li><a href="#contact">Contato</a></li>
          </ul>
        </nav>

      </header>

      {isMobileMenuOpen && <MenuModal close={handleMenuMobile} />}

      <main>
        <section className={styles.about} id="about">

          <h1>Sobre</h1>

          <div className={styles.information}>
            <img src="https://avatars.githubusercontent.com/u/62447520?v=4" alt="Caio Gabriel" />
            <p className={styles.aboutTitle}>
              Desenvolvedor Web &amp; Mobile
            </p>
            <p className={styles.aboutText}>
              Conheci a programação á 3 anos durante o curso técnico em redes de computadores.
              Inicialmente desenvolvia com Java, porém, logo após conhecer o Python, 
              conheci o Javascript, que é a linguagem que mais utilizo hoje em dia juntamente
              com seus frameworks.
            </p>
          </div>

          <div className={styles.school}>
            <IoSchoolOutline />
            <div>
              <h2>Téc. Redes de Computadores</h2>
              <p>EEEP Maria José Medeiros</p>
              <span>(2018-2020)</span>
            </div>
          </div>

        </section>

        <section className={styles.skills} id="skilss">
          <h1>Habilidades</h1>

          <div className={styles.skillsLogo}>

            <div>
              <AiFillHtml5 />
              <p>HTML5</p>
            </div>

            <div>
              <DiCss3 />
              <p>CSS3</p>
            </div>

            <div>
              <DiJavascript />
              <p>JAVASCRIPT</p>
            </div>

            <div>
              <DiPython />
              <p>PYTHON</p>
            </div>

            <div>
              <FaDocker />
              <p>DOCKER</p>
            </div>

            <div>
              <FaGitAlt />
              <p>GIT</p>
            </div>

          </div>

        </section>

        <section className={styles.projects} id="projects">
          <h1>Projetos</h1>

          <div className={styles.cardProjects}>
            {repos.map(item =>
              <div key={item.id}>
                <h1>{item.name}</h1>
                <p>{item.description || 'Sem descrição'}</p>
                <a href={item.html_url} target="_blank" rel="noopener noreferrer">Ver mais</a>
              </div>)}
          </div>

          <a href="https://github.com/caiokronuz?tab=repositories" target="_blank" rel="noopener noreferrer">Ver mais projetos</a>

        </section>

        <section className={styles.contact} id="contact">
          <h1>Contato</h1>
          <div>
            <div>
              <AiOutlineMail />
              <a href="mailto:caiogabriel135@gmail.com">caiogabriel135@gmail.com</a>
            </div>
            <div>
              <AiFillLinkedin />
              <a href="https://www.linkedin.com/in/caio-gabriel-5381651b5/" target="_blank" rel="noopener noreferrer">Caio Gabriel</a>
            </div>
            <div>
              <AiFillGithub />
              <a href="https://www.github.com/caiokronuz" target="_blank" rel="noopener noreferrer">@caiokronuz</a>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <span>&copy; Caio Gabriel - 2021</span>
        <div>
          <a href="mailto:caiogabriel135@gmail.com"><AiOutlineMail /></a>
          <a href="https://www.linkedin.com/in/caio-gabriel-5381651b5/" target="_blank" rel="noopener noreferrer"><AiFillLinkedin /></a>
          <a href="https://www.github.com/caiokronuz" target="_blank" rel="noopener noreferrer"><AiFillGithub /></a>
        </div>
      </footer>

    </div>
  );
}

export default App;
