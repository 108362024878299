import { AiOutlineCloseCircle } from 'react-icons/ai'

import styles from './modal.module.scss';
export default function MenuModal({ close }) {
    return (
        <div className={styles.modal} onClick={close}>
            <nav className={styles.menuNav}>
                <ul>
                    <li><AiOutlineCloseCircle /></li>
                    <li><a href="#about">Sobre</a></li>
                    <li><a href="#skilss">Habilidades</a></li>
                    <li><a href="#projects">Projetos</a></li>
                    <li><a href="#contact">Contato</a></li>
                </ul>
            </nav>
        </div>
    )
}